import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "comments","details","icon" ,"icond"]

  toggleComments() {
     if(this.commentsTarget.classList.contains("hide"))
     {
       this.commentsTarget.classList.remove("hide", "d-none")
       this.commentsTarget.classList.add("show")
       this.iconTarget.classList.remove("fa-plus-circle")
       this.iconTarget.classList.add("fa-minus-circle")
     }
     else
     {
       this.commentsTarget.classList.add("hide", "d-none")
       this.commentsTarget.classList.remove("show")
       this.iconTarget.classList.remove("fa-minus-circle")
       this.iconTarget.classList.add("fa-plus-circle")
     }
  }

  toggleDetails() {
    if(this.detailsTarget.classList.contains("hide"))
    {
      this.detailsTarget.classList.remove("hide", "d-none")
      this.detailsTarget.classList.add("show")
      this.icondTarget.classList.remove("fa-plus-circle")
      this.icondTarget.classList.add("fa-minus-circle")
    }
    else
    {
      this.detailsTarget.classList.add("hide", "d-none")
      this.detailsTarget.classList.remove("show")
      this.icondTarget.classList.remove("fa-minus-circle")
      this.icondTarget.classList.add("fa-plus-circle")
    }
  }



}
