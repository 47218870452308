import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [
        'subBtn',
        'languages',
        'mode',
        'langs'
    ]
    static values = {
        currentyselected: String,
        mode: String,
        languages: Array ,
        traLang: String}



    connect() {
        let c = this;
        this.currentyselectedValue = ""
        this.modeValue = this.data.get("mode")
        this.languagesValue = []
        this.traLangValue = ""
        this.element.querySelectorAll("li").forEach(function(item) {
            const radioButton = item.querySelector('input[type="radio"]');
            if (radioButton.checked) {
                item.classList.add('selected');
                c.currentySelectedValue = radioButton.value
            }
        } )
        console.log(this.subBtnTarget)
    }

    clicklevel(event ) {
        let that=this
        let theTarget = event.currentTarget
        this.subBtnTarget.disabled = true
        const radioButton = event.target.querySelector('input[type="radio"]');
        if (radioButton) {
            radioButton.checked = true;
        }
        this.element.querySelectorAll("li").forEach(function(item) {
            item.classList.remove(("selected"))        })

        // Add the "selected" class to the clicked <li> element
        event.target.classList.add('selected');
        let lvlId = radioButton.value
        this.currentyselectedValue = lvlId
        let langs = JSON.parse(theTarget.dataset.applyingPosslangsValue);
        switch(that.modeValue){

            case "professional":
                if(langs.length == 0)
                {

                    that.languagesTarget.innerHTML=""
                    that.languagesValue = []
                    that.prepareSubmit()
                }
                else
                {
                    that.displayRegButtons(langs)
                }
                break
            case "trainee":
                if(langs.length == 0)
                {

                    alert("No languages available for this trainee level")
                }
                else
                {
                    that.displayTraButtons(langs)
                }
        }



    }

    prepareSubmit(){

        if(this.modeValue == 'professional')
        {
            this.langsTarget.value = this.languagesValue.join(",")
            if (this.langsTarget.value == "")
            {
                this.subBtnTarget.value = "Apply for this Category"
            }
            else
            {
                this.subBtnTarget.value = "Apply for this Category and Language(s)"
            }
        }
        else
        {
            this.langsTarget.value = this.traLangValue
            this.subBtnTarget.value = "Apply for this Category and Language"
        }

        this.subBtnTarget.disabled =  false


    }

    displayRegButtons(langs){

        let btns = []
        let alert = ""
        langs.forEach((lang)=> {
          let alt = this.handleRelay(lang)
          btns.push( `<li class='btn btn-sm btn-warning m-2 ' data-action='click->applying#chooseLanguage' data-lang='${lang}'>${lang + ' <=> '+ alt}</li>`)
        })
        if (langs.length > 0)
        {
            alert="<div class='alert alert-info'>Now select your languages: </div>"
        }

        let out=`<div class='col-xs-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>${alert}<ul class='registration'>${btns.join('')}</ul></div>`
        this.languagesTarget.innerHTML=out
    }

    displayTraButtons(langs){
        let btns = []
        let alert = ""
        langs.forEach((lang)=> {
          let alt = this.handleRelay(lang)
          btns.push( `<li class='btn btn-sm btn-warning m-2 ' data-action='click->applying#chooseTraLanguage' data-lang='${lang}'>${lang + ' <=> '+ alt}</li>`)
        })
        if (langs.length > 0)
        {
            alert="<div class='alert alert-info'>Now select your language: </div>"
        }

        let out=`<div class='col-xs-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>${alert}<ul id='myTraButtons' class='registration'>${btns.join('')}</ul></div>`
        this.languagesTarget.innerHTML=out
    }

    handleRelay(lang){
      let alt = ""
      const reg = /Relay/
      if(lang.match(reg))
      {
        alt = "BSL"
      }
      else {
        {
          alt = "English"
        }
      }
      return alt
    }

    chooseLanguage(event) {

        let lang = event.target.dataset.lang
        if(this.languagesValue.includes(lang))
        {
            this.languagesValue = this.languagesValue.filter(item => item !== lang)
            event.target.classList.remove("selected")
        }
        else
        {
            this.languagesValue.push(lang)
            event.target.classList.add("selected")
        }

        this.prepareSubmit()
    }

    chooseTraLanguage(event) {

        let lang = event.target.dataset.lang
        this.traLangValue = lang
        let btnContainer = document.getElementById("myTraButtons")
        let allTraButtons = Array.from(btnContainer.querySelectorAll("li"))
        allTraButtons.forEach((btn)=> {btn.classList.remove("selected")})
        event.target.classList.add("selected")
        this.prepareSubmit()
    }




}
